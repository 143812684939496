import styled from 'styled-components/macro'

export const Heading = styled.div({
  fontWeight: 600,
  marginBottom: 10,
})
export const Paragraph = styled.div({
  marginBottom: 15,
  whiteSpace: 'pre-line',
})
export const Container = styled.div({
  maxWidth: 600,
  margin: '60px auto',
  fontSize: 16,
})
export const Separator = styled.div({
  borderBottom: '1px solid #ddd',
  height: 1,
  marginTop: 30,
  marginBottom: 30,
})
export const BottomSection = styled.div({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 10,
})
