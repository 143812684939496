import React from 'react'
import { useTranslation } from 'react-i18next'

import { useGlobalState } from '../data/store'
import useStepsCompleted from '../components/hooks/useStepsCompleted'
import useInputState from '../components/hooks/useInputState'
import SubmitButton from '../components/reusables/SubmitButton'
import {
  MainContainer,
  MainContent,
  Heading,
  Description,
  FormArea,
} from '../components/reusables/page-elements'
import { TextInputSection } from '../components/reusables/form-elements'

function Start({ history }) {
  const { t } = useTranslation()
  const reUseStepsCompleted = useStepsCompleted()
  const [
    {
      startPage: { values: startPageValues, labels: startPageLabels },
    },
    dispatch,
  ] = useGlobalState()
  const [inputs, setInput] = useInputState({ initialValue: startPageValues })
  const handleChange = ({ target: { name, value } }) => {
    setInput({ name, value })
  }
  const handleSubmit = e => {
    reUseStepsCompleted({ steps: [0] })
    dispatch({
      key: 'startPage',
      value: inputs,
    })
    history.push('/2')
  }
  const isSubmittable = Object.values(inputs).every(item => item.length)
  return (
    <MainContainer>
      <MainContent>
        <Heading>{t('startPage.heading')}</Heading>
        <Description>{t('startPage.descr')}</Description>
        <FormArea>
          {Object.keys(inputs).map((item, index) => (
            <TextInputSection
              key={index}
              {...{
                handleChange,
                value: inputs[item],
                name: item,
                label: startPageLabels[item],
                tabIndex: index === 0 ? 0 : null,
              }}
            />
          ))}
          <SubmitButton
            isSubmittable={isSubmittable}
            handleSubmit={handleSubmit}
          />
        </FormArea>
      </MainContent>
    </MainContainer>
  )
}

export default Start
