import React from 'react'
import styled from 'styled-components/macro'
import { withRouter, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useGlobalState } from '../data/store'
import { colors, sizes } from '../lib/constants'
import { MainContainer } from '../components/reusables/page-elements'
import { InvisibleSidebar } from './reusables/Sidebar'

const Wrapper = styled.div({
  marginTop: 50,
  marginBottom: 35,
  display: 'flex',
  minWidth: sizes.mainContent,
  maxWidth: sizes.mainContent,
})
const Step = styled(({ isCurrent, isClickable, ...rest }) => (
  <Link {...rest} />
))(({ isCurrent, isClickable }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 600,
  fontSize: 15,
  flex: 1,
  alignItems: 'center',
  paddingBottom: 15,
  borderBottom: '3px solid',
  ...(!isClickable && {
    pointerEvents: 'none',
  }),
  borderBottomColor: isCurrent ? colors.baseGreen : 'transparent',
  opacity: isCurrent ? 1 : 0.3,
  ':hover, :focus, :active': {
    textDecoration: 'none',
    ...(!isCurrent && { opacity: 0.6 }),
  },
}))

const data = [
  {
    path: '1',
    content: '1. Start',
  },
  {
    path: '2',
    content: '2. Hinder',
  },
  {
    path: '3',
    content: '3. Situation',
  },
  {
    path: '4',
    content: '4. Önskat läge',
  },
  {
    path: '5',
    content: '5. Sammanfattning',
  },
]

function Steps({ location: { pathname } }) {
  const { t } = useTranslation()
  const [
    {
      stepsCompleted: { values: stepsCompleted },
    },
  ] = useGlobalState()
  return (
    <MainContainer>
      <InvisibleSidebar />
      <Wrapper>
        {data.map(({ path, content }, index) => {
          const isCurrent = pathname.includes(path)
          return (
            <Step
              to={path}
              isCurrent={isCurrent}
              isClickable={stepsCompleted[index]}
              key={index}
            >
              {t('steps.' + (index + 1))}
            </Step>
          )
        })}
      </Wrapper>
      <InvisibleSidebar />
    </MainContainer>
  )
}

export default withRouter(Steps)
