import React from 'react'
import styled from 'styled-components/macro'
import ReactToPrint from 'react-to-print'

import Button from '../reusables/Button'
import { ReactComponent as Printer } from '../../assets/images/printer.svg'

const Wrapper = styled.div({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  '@media print': { display: 'none !important' },
})

function Buttons({ componentRef }) {
  return (
    <Wrapper>
      <ReactToPrint
        trigger={() => (
          <Button
            small={1}
            white={1}
            css={{ marginRight: 15, display: 'flex' }}
          >
            <Printer
              css={{
                width: '18px',
                marginRight: '5px',
              }}
            />
            Skriv ut
          </Button>
        )}
        content={componentRef}
      />
      {/* <Button small={1} white={1} as={Link} to="/start">
        + Skapa ny
      </Button> */}
    </Wrapper>
  )
}

export default Buttons
