import { useReducer } from 'react'

function useInputState({ initialValue }) {
  return useReducer((state, { name, value }) => {
    return {
      ...state,
      [name]: value,
    }
  }, initialValue)
}

export default useInputState
