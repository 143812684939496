import React, { useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useGlobalState } from '../data/store'
import useStepsCompleted from '../components/hooks/useStepsCompleted'
import {
  MainContainer,
  MainContent,
  Heading,
  Description,
} from '../components/reusables/page-elements'
import Sidebar, { InvisibleSidebar } from '../components/reusables/Sidebar'
import ObstaclesListing from '../components/obstacles/ObstaclesListing'
import ObstaclesSelecting from '../components/obstacles/ObstaclesSelecting'

const sortForEmptyInputs = formInputs =>
  formInputs.reduce((acc, cur) => {
    cur === '' ? acc.push(cur) : acc.unshift(cur)
    return acc
  }, [])

function Obstacles({ history, fieldInformation }) {
  const obstaclesExample = fieldInformation.find(
    item => item.fieldId === 'obstacle',
  )
  const { t } = useTranslation()
  const reUseStepsCompleted = useStepsCompleted()
  const [step, setStep] = useState(0)
  const [
    {
      obstaclesPage: { values: obstaclesPageValues },
      selectedObstacleIndex: { values: selectedObstacleIndex },
    },
    dispatch,
  ] = useGlobalState()
  const [selectedObstacle, setSelectedObstacle] = useState(
    selectedObstacleIndex,
  )
  const [formInputs, setFormInput] = useReducer((state, newState) => {
    return state.map((item, index) =>
      index === newState.position ? newState.value : item,
    )
  }, obstaclesPageValues)
  const handleTextChange = event => {
    const { name, value } = event.target
    setFormInput({ position: Number(name), value })
  }
  const handleSubmit = () => {
    reUseStepsCompleted({ steps: [1] })
    const sortedFormInputs = sortForEmptyInputs(formInputs)
    dispatch({
      key: 'obstaclesPage',
      value: sortedFormInputs,
    })
    dispatch({
      key: 'selectedObstacleIndex',
      value: selectedObstacle,
    })
    history.push('/3')
  }
  const isSubmittable = selectedObstacle !== null
  return (
    <MainContainer>
      <InvisibleSidebar />
      <MainContent>
        <Heading>{t('obstaclesPage.heading')}</Heading>
        <Description>
          {step === 0 ? t('obstaclesPage.descr1') : t('obstaclesPage.descr2')}
        </Description>
        {step === 0 ? (
          <ObstaclesListing {...{ handleTextChange, formInputs, setStep }} />
        ) : (
          <ObstaclesSelecting
            {...{
              formInputs,
              setSelectedObstacle,
              selectedObstacle,
              isSubmittable,
              handleSubmit,
            }}
          />
        )}
      </MainContent>
      <Sidebar {...obstaclesExample} />
    </MainContainer>
  )
}

export default Obstacles
