import React from 'react'
import { withRouter } from 'react-router-dom'

import { TopBarLoggedIn } from '../components/TopBar'
import useDataApi from '../components/hooks/useDataApi'
import filterContentfulResponse from '../lib/filterContentfulResponse'
import Steps from '../components/Steps'
import LoadingIndicator from '../components/reusables/LoadingIndicator'
import LoggedInSwitch from './LoggedInSwitch'

const API_BASE_URL = 'https://cdn.contentful.com'
const API_SPACE_ID = '62ncoiyebkcj'
const API_TOKEN =
  'b5a47d97003b5a60ad012070580c47d994bfd1d8b9b8744c2256a3ee21bfe90b'
const contentfulUrl = `${API_BASE_URL}/spaces/${API_SPACE_ID}/entries`

function LoggedInRouting({ location }) {
  const isIntroPage = location.pathname === '/'
  const { data, isLoading, isError } = useDataApi({
    url: contentfulUrl,
    jwtToken: API_TOKEN,
  })
  if (isLoading) {
    return <LoadingIndicator />
  }
  if (isError)
    return 'Something went wrong fetching the content, please refresh the page.'
  const introParagraphs = filterContentfulResponse(
    data.hits[0].items,
    'introductionParagraph',
  )
  const fieldInformation = filterContentfulResponse(
    data.hits[0].items,
    'fieldInformation',
  )
  return (
    <>
      <TopBarLoggedIn isIntroPage={isIntroPage} />
      {!isIntroPage && <Steps />}
      <LoggedInSwitch
        fieldInformation={fieldInformation}
        introParagraphs={introParagraphs}
      />
    </>
  )
}

export default withRouter(LoggedInRouting)
