import React, { useState, useReducer } from 'react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { useGlobalState } from '../data/store'
import useStepsCompleted from '../components/hooks/useStepsCompleted'
import SubmitButton from '../components/reusables/SubmitButton'
import {
  MainContainer,
  MainContent,
  Heading,
  FormAreaVertical,
} from '../components/reusables/page-elements'
import { TextInputSection, Label } from '../components/reusables/form-elements'
import SelectedObstacle from '../components/reusables/SelectedObstacle'
import MultiInputSection from '../components/reusables/MultiInputSection'
import Sidebar, { InvisibleSidebar } from '../components/reusables/Sidebar'

export const obstacleTypeList = [
  'Inlärning som att uppgifterna är för svåra',
  'Sociala faktorer som att följa klassrumsregler och att komma överens med lärare och klasskamrater',
]

function Situations({ history, situationsExample }) {
  const { t } = useTranslation()
  const reUseStepsCompleted = useStepsCompleted()
  const [
    {
      situationsPage: { values: situationsPage },
    },
    dispatch,
  ] = useGlobalState()
  const [allSituations, setAllSituations] = useState(
    situationsPage.allSituations,
  )
  const [selectedTypesOfObstacle, setTypeOfObstacle] = useReducer(
    (state, newState) => {
      if (state.includes(newState)) {
        return state.filter(item => item !== newState)
      }
      return state.concat(newState)
    },
    situationsPage.selectedTypeOfObstacle,
  )
  const handleSubmit = () => {
    reUseStepsCompleted({ steps: [2] })
    dispatch({
      key: 'situationsPage',
      value: {
        allSituations,
        selectedTypeOfObstacle: selectedTypesOfObstacle,
      },
    })
    history.push('/4')
  }
  const isSubmittable = allSituations.length
  return (
    <MainContainer>
      <InvisibleSidebar />
      <MainContent>
        <Heading>{t('situationsPage.heading')}</Heading>
        <SelectedObstacle />
        <FormAreaVertical>
          <TextInputSection
            {...{
              handleChange: e => setAllSituations(e.target.value),
              label: t('situationsPage.allSituations'),
              textArea: true,
              value: allSituations,
              name: 'allSituations',
              tabIndex: 0,
            }}
          />
          <Label css={{ marginTop: 40, marginBottom: 20 }}>
            {t('situationsPage.choose')}
          </Label>
          <MultiInputSection
            type="checkbox"
            items={obstacleTypeList}
            handleChange={setTypeOfObstacle}
            selectedValue={selectedTypesOfObstacle}
          />
          <SubmitButton
            isSubmittable={isSubmittable}
            handleSubmit={handleSubmit}
          />
        </FormAreaVertical>
      </MainContent>
      <Sidebar {...situationsExample} />
    </MainContainer>
  )
}

export default Situations
