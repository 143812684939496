import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import { StateProvider } from './data/store'
import stateTree from './data/stateTree'
import { isProductionEnv } from './lib/utilFunctions'
import FullStory from './components/FullStory'
import LoggedInRouting from './routing/LoggedInRouting'
import LoggedOutRouting from './routing/LoggedOutRouting'

function App() {
  const isAuthenticated = JSON.parse(
    window.localStorage.getItem('isAuthenticated'),
  )
  if (isAuthenticated && isProductionEnv()) {
    window.Intercom('boot', {
      user_id: isAuthenticated.username,
      app_id: 're6bwqlr',
    })
  }

  return (
    <StateProvider initialState={stateTree({ hasSampleData: false })}>
      <Router>
        {isAuthenticated && isAuthenticated.value ? (
          <>
            {isProductionEnv() && <FullStory />}
            <LoggedInRouting />
          </>
        ) : (
          <LoggedOutRouting />
        )}
      </Router>
    </StateProvider>
  )
}

export default App
