import React from 'react'

import { FormAreaVertical } from '../reusables/page-elements'
import MultiInputSection from '../reusables/MultiInputSection'
import SubmitButton from '../reusables/SubmitButton'

function ObstaclesListing({
  formInputs,
  setSelectedObstacle,
  selectedObstacle,
  isSubmittable,
  handleSubmit,
}) {
  return (
    <FormAreaVertical>
      <MultiInputSection
        items={formInputs.filter(item => item.length)}
        handleChange={setSelectedObstacle}
        selectedValue={selectedObstacle}
      />
      <SubmitButton isSubmittable={isSubmittable} handleSubmit={handleSubmit} />
    </FormAreaVertical>
  )
}

export default ObstaclesListing
