import styled from 'styled-components/macro'

import { colors } from '../../lib/globals'
import TransparentButton from './TransparentButton'

const Button = styled(TransparentButton)(
  ({ shrunk, blue, isFullWidth, disabled, white, small }) => ({
    backgroundColor: colors.baseGreen,
    color: '#fff',
    borderRadius: '4px',
    padding: '12px 60px',
    display: 'inline-block',
    textDecoration: 'none !important',
    ':hover': {
      backgroundColor: '#1e9251',
    },
    ...(shrunk && {
      padding: '12px 20px',
    }),
    ...(isFullWidth && {
      width: '100%',
    }),
    ...(blue && {
      backgroundColor: colors.baseBlue,
      color: '#fff',
      transition: 'all 0.3s',
      ':hover': {
        backgroundColor: colors.baseBlue,
      },
    }),
    ...(white && {
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      color: 'inherit',
      ':hover': {
        backgroundColor: '#eee',
      },
    }),
    ...(small && {
      padding: '5px 10px',
    }),
    ...(disabled && {
      opacity: 0.7,
      pointerEvents: 'none',
    }),
  }),
)

export default Button
