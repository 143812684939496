import { useState, useEffect } from 'react'

const useDataApi = ({ url, jwtToken }, initialData = { hits: [] }) => {
  const [data, setData] = useState(initialData)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const fetchData = async () => {
    setIsError(false)
    try {
      const response = await fetch(url, {
        headers: new Headers({
          Authorization: 'Bearer ' + jwtToken,
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
      const data = await response.json()
      setData(old => ({
        hits: old.hits.concat(data),
      }))
    } catch (error) {
      setIsError(true)
    }
    setIsLoading(false)
  }
  useEffect(() => {
    fetchData()
  }, [])
  return { data, isLoading, isError }
}

export default useDataApi
