import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import pages from '../locales/pages.json'
import general from '../locales/general.json'

const resources = {
  sv: {
    translation: { ...pages, ...general },
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'sv',
  fallbackLng: 'sv',
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    wait: false,
    nsMode: 'fallback', // set it to fallback to let passed namespaces to translated hoc act as fallbacks
  },
})

export default i18n
