import { useGlobalState } from '../../data/store'

function useStepsCompleted() {
  const [
    {
      stepsCompleted: { values },
    },
    dispatch,
  ] = useGlobalState()

  return ({ steps }) => {
    const newStepsCompleted = values.slice()
    steps.forEach(step => {
      newStepsCompleted[step] = true
    })
    dispatch({
      key: 'stepsCompleted',
      value: newStepsCompleted,
    })
  }
}

export default useStepsCompleted
