import React, { useRef } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import 'styled-components/macro'

import { useGlobalState } from '../data/store'
import { MainContainer } from '../components/reusables/page-elements'
import { Content as SelectedObstacle } from '../components/reusables/SelectedObstacle'
import {
  SingleLineParagraph,
  StyledSheet,
  HeadingSection,
  TeacherSection,
} from '../components/summary/summary-elements'
import Section from '../components/summary/Section'
import Paragraph from '../components/summary/Paragraph'
import Buttons from '../components/summary/Buttons'
import { ListItem } from '../components/summary/ListItems'
import { obstacleTypeList } from './Situations'

function Summary() {
  const { t } = useTranslation()
  let componentRef = useRef(null)
  const [
    {
      startPage,
      selectedObstacleIndex: { values: selectedObstacleIndex },
      obstaclesPage: { values: obstaclesPage },
      situationsPage: { values: situationsPage },
      desiredStatePage,
    },
  ] = useGlobalState()
  const selectedObstacle = obstaclesPage[selectedObstacleIndex]
  const todaysDate = moment().format('DD MMMM YYYY')
  return (
    <MainContainer>
      <StyledSheet ref={el => (componentRef = el)}>
        <HeadingSection
          title={t('summaryPage.heading')}
          rightContent={<Buttons componentRef={() => componentRef} />}
        />
        <Section
          heading={t('summaryPage.facts')}
          contentStyling={{
            display: 'flex',
            flexFlow: 'wrap',
          }}
        >
          {Object.keys(startPage.values).map((item, index) => (
            <SingleLineParagraph
              css={{ minWidth: '50%' }}
              key={index}
              first={startPage.labels[item]}
              second={startPage.values[item]}
            />
          ))}
          <SingleLineParagraph first="Datum" second={todaysDate} />
        </Section>
        <Section heading={t('summaryPage.allObstacles')}>
          {obstaclesPage
            .filter(item => item.length)
            .map((item, index) => (
              <ListItem key={index}>{item}</ListItem>
            ))}
        </Section>
        <Section heading={t('summaryPage.choosenObstacle')}>
          <SelectedObstacle>
            <ListItem css={{ marginBottom: 0 }}>{selectedObstacle}</ListItem>
          </SelectedObstacle>
        </Section>
        <Section heading={t('summaryPage.situations')}>
          <Paragraph
            title={t('situationsPage.allSituations')}
            content={situationsPage.allSituations}
          />
          <Paragraph
            css={{
              paddingBottom: 0,
            }}
            title={t('situationsPage.choose')}
            content={obstacleTypeList.map((item, index) => {
              const isNotSelected = !situationsPage.selectedTypeOfObstacle.includes(
                index,
              )
              return (
                <ListItem
                  isNotSelected={isNotSelected}
                  css={{ marginLeft: 20 }}
                  key={index}
                >
                  {item}
                </ListItem>
              )
            })}
          />
        </Section>
        <Section heading={t('summaryPage.desiredState')}>
          {Object.keys(desiredStatePage.values).map((item, index) => (
            <TeacherSection isSelected={index !== 0} key={index}>
              <Paragraph
                title={desiredStatePage.labels[item]}
                content={desiredStatePage.values[item]}
              />
            </TeacherSection>
          ))}
        </Section>
      </StyledSheet>
    </MainContainer>
  )
}

export default Summary
