import React from 'react'
import styled from 'styled-components/macro'

const Row = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 25,
})
const RadioInput = styled.input({
  marginRight: 10,
  cursor: 'pointer',
})
const Label = styled.label({
  flex: 1,
  cursor: 'pointer',
})

function MultiInputSection({
  items,
  handleChange,
  selectedValue,
  type = 'radio',
}) {
  return items.map((item, index) => (
    <Row key={index}>
      <RadioInput
        type={type}
        id={index}
        value={index}
        checked={
          type === 'radio'
            ? selectedValue === index
            : selectedValue.includes(index)
        }
        onChange={() => handleChange(index)}
      />
      <Label htmlFor={index}> {item}</Label>
    </Row>
  ))
}

export default MultiInputSection
