import React from 'react'
import styled from 'styled-components/macro'

import { Sheet } from '../reusables/page-elements'
import { colors } from '../../lib/globals'

export const TeacherSection = styled.div(({ isSelected }) => ({
  borderLeft: `3px solid ${isSelected ? colors.baseGreen : 'transparent'}`,
  paddingLeft: 15,
  marginLeft: -15,
}))

export const StyledSheet = styled(Sheet)({
  minWidth: 800,
  maxWidth: 800,
  paddingLeft: 50,
  paddingRight: 50,
  margin: '0 auto 100px',
  ' @page': {
    margin: '20mm 10mm 20mm 10mm',
  },
  '@media print': {
    boxShadow: 'none',
  },
})
export const HeadingTitle = styled.div({
  fontSize: 26,
})
export const HeadingContainer = styled.div({
  marginBottom: 40,
  display: 'flex',
})

export function HeadingSection({ title, rightContent }) {
  return (
    <HeadingContainer>
      <HeadingTitle>{title}</HeadingTitle>
      <div css={{ marginLeft: 'auto' }}>{rightContent}</div>
    </HeadingContainer>
  )
}

const SingleLineContainer = styled.div({
  marginBottom: 15,
  display: 'flex',
})
const SingleLineKey = styled.div({
  minWidth: 100,
})
const SingleLineValue = styled.div({})

export function SingleLineParagraph({ first, second, ...rest }) {
  return (
    <SingleLineContainer {...rest}>
      <SingleLineKey>{first}: </SingleLineKey>
      <SingleLineValue>{second}</SingleLineValue>
    </SingleLineContainer>
  )
}
