import React from 'react'
import { Helmet } from 'react-helmet'
import { Route, Redirect, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Intro from '../pages/Intro'
import Start from '../pages/Start'
import Obstacles from '../pages/Obstacles'
import Situations from '../pages/Situations'
import DesiredState from '../pages/DesiredState'
import Summary from '../pages/Summary'

const renderHelmet = title => (
  <Helmet>
    <title>{title + ' |'} När ett hinder uppstår - Grundskolan</title>
  </Helmet>
)

function LoggedInSwitch({ fieldInformation, introParagraphs }) {
  const { t } = useTranslation()
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => (
          <>
            {renderHelmet(t('introPage.pageTitle'))}
            <Intro introParagraphs={introParagraphs} />
          </>
        )}
      />
      <Route
        path="/1"
        render={props => (
          <>
            {renderHelmet(t('startPage.pageTitle'))}
            <Start {...props} />
          </>
        )}
      />
      <Route
        path="/2"
        render={props => (
          <>
            {renderHelmet(t('obstaclesPage.pageTitle'))}
            <Obstacles fieldInformation={fieldInformation} {...props} />
          </>
        )}
      />
      <Route
        path="/3"
        render={props => (
          <>
            {renderHelmet(t('situationsPage.pageTitle'))}
            <Situations
              situationsExample={fieldInformation.find(
                x => x.fieldId === 'situation',
              )}
              {...props}
            />
          </>
        )}
      />
      <Route
        path="/4"
        render={props => (
          <>
            {renderHelmet(t('desiredStatePage.pageTitle'))}
            <DesiredState
              desiredStateExample={fieldInformation.find(
                x => x.fieldId === 'wish',
              )}
              {...props}
            />
          </>
        )}
      />
      <Route
        path="/5"
        render={() => (
          <>
            {renderHelmet(t('summaryPage.pageTitle'))}
            <Summary />
          </>
        )}
      />
      <Redirect to="/" />
    </Switch>
  )
}

export default LoggedInSwitch
