import React from 'react'
import styled from 'styled-components'

const Container = styled.div({
  paddingBottom: 30,
})
const Title = styled.div({
  marginBottom: 10,
  fontWeight: 600,
})
const Content = styled.div({
  minHeight: 20,
  whiteSpace: 'pre-wrap',
})

function Paragraph({ title, content, ...rest }) {
  return (
    <Container {...rest}>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </Container>
  )
}

export default Paragraph
