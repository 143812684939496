import { createGlobalStyle } from 'styled-components/macro'
import styledNormalize from 'styled-normalize'

import { colors, fontSizes } from './lib/constants'

const GlobalStyles = createGlobalStyle({
  ...styledNormalize,
  html: {
    boxSizing: 'border-box',
  },
  '*, *:before, *:after': {
    boxSizing: 'inherit',
  },
  body: {
    fontFamily: 'Open Sans, sans-serif',
    fontSize: fontSizes.default,
    lineHeight: 'normal',
    overflowY: 'scroll',
    backgroundColor: colors.bodyBgColor,
    color: colors.baseBlack,
    position: 'relative',
    textSizeAdjust: '100%',
    minHeight: 700,
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: 0.4,
    'input:focus, select:focus, textarea:focus, button:focus': {
      outline: 'none',
    },
  },
  button: {
    letterSpacing: 'inherit',
    fontWeight: 'inherit',
  },
  a: {
    color: 'inherit',
    textDecoration: 'none',
    ':focus, :hover, :active': {
      textDecoration: 'underline',
    },
  },
  p: {
    marginBlockStart: 0,
  },
})

export default GlobalStyles
