import React from 'react'
import 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { useGlobalState } from '../data/store'
import useStepsCompleted from '../components/hooks/useStepsCompleted'
import useInputState from '../components/hooks/useInputState'
import SubmitButton from '../components/reusables/SubmitButton'
import {
  MainContainer,
  MainContent,
  Heading,
  FormAreaVertical,
} from '../components/reusables/page-elements'
import { TextInputSection } from '../components/reusables/form-elements'
import SelectedObstacle from '../components/reusables/SelectedObstacle'
import Sidebar, { InvisibleSidebar } from '../components/reusables/Sidebar'

function DesiredPage({ history, desiredStateExample }) {
  const { t } = useTranslation()
  const reUseStepsCompleted = useStepsCompleted()
  const [
    {
      desiredStatePage: { values: desiredStatePage },
    },
    dispatch,
  ] = useGlobalState()
  const [inputs, setInput] = useInputState({ initialValue: desiredStatePage })
  const handleChange = ({ target: { name, value } }) => {
    setInput({ name, value })
  }
  const handleSubmit = () => {
    reUseStepsCompleted({ steps: [3, 4] })
    dispatch({
      key: 'desiredStatePage',
      value: inputs,
    })
    history.push('/5')
  }
  const isSubmittable = inputs.studentTodos.length
  const commonProps = {
    handleChange,
    textArea: true,
  }
  return (
    <MainContainer>
      <InvisibleSidebar />
      <MainContent>
        <Heading>{t('desiredStatePage.heading')}</Heading>
        <SelectedObstacle />
        <FormAreaVertical>
          <TextInputSection
            {...{
              ...commonProps,
              label: t('desiredStatePage.studentTodos'),
              value: inputs.studentTodos,
              name: 'studentTodos',
              tabIndex: 0,
            }}
          />

          <div
            css={{
              borderTop: '1px solid #eee',
              paddingTop: '25px',
              marginBottom: '40px',
              fontSize: '22px',
              fontWeight: '600',
            }}
          >
            Lärarens beteende/ Möjliga insatser
          </div>
          <TextInputSection
            {...{
              ...commonProps,
              label: t('desiredStatePage.pastAttempts'),
              textArea: true,
              value: inputs.pastAttempts,
              name: 'pastAttempts',
            }}
          />
          <TextInputSection
            {...{
              ...commonProps,
              label: t('desiredStatePage.teacherTodos'),
              value: inputs.teacherTodos,
              name: 'teacherTodos',
            }}
          />
          <TextInputSection
            {...{
              inputStyling: {
                width: 250,
                maxWidth: 250,
              },
              handleChange,
              label: t('desiredStatePage.evaluationDate'),
              value: inputs.evaluationDate,
              name: 'evaluationDate',
            }}
          />
          <SubmitButton
            isSubmittable={isSubmittable}
            handleSubmit={handleSubmit}
          />
        </FormAreaVertical>
      </MainContent>
      <Sidebar {...desiredStateExample} />
    </MainContainer>
  )
}

export default DesiredPage
