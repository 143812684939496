import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'

import Login from '../pages/Login'
import { TopBarLoggedOut } from '../components/TopBar'

function LoggedOutRouting() {
  return (
    <>
      <TopBarLoggedOut />
      <Switch>
        <Route path="/login" component={Login} />
        <Redirect to="/login" />
      </Switch>
    </>
  )
}

export default LoggedOutRouting
