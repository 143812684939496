import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { FormArea, SubmitButtonWrapper } from '../reusables/page-elements'
import Button from '../reusables/Button'
import { inputStyling, focusStyling } from '../reusables/form-elements'

const TextInput = styled.input(({ value }) => ({
  ...inputStyling,
  marginBottom: 15,
  border: '1px solid transparent',
  backgroundColor: value.length ? '#fff' : '#f5f5f5',
  ':focus': {
    ...focusStyling,
  },
}))

function ObstaclesListing({ handleTextChange, formInputs, setStep }) {
  const { t } = useTranslation()
  return (
    <FormArea>
      {formInputs.map((item, index) => (
        <div css={{ display: 'flex' }} key={index}>
          <div
            css={{
              margin: '2px 14px 0 0',
              fontSize: '22px',
            }}
          >
            •
          </div>
          <TextInput
            autoFocus={index === 0 ? true : false}
            type="text"
            name={index}
            value={item}
            onChange={handleTextChange}
          />
        </div>
      ))}
      <SubmitButtonWrapper>
        <Button
          blue
          disabled={!formInputs.join('').length}
          shrunk
          onClick={e => {
            e.preventDefault()
            setStep(1)
          }}
        >
          {t('obstaclesPage.button1')}
        </Button>
      </SubmitButtonWrapper>
    </FormArea>
  )
}

export default ObstaclesListing
