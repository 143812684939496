import React from 'react'
import styled from 'styled-components/macro'

import { colors, borderRadiuses } from '../../lib/constants'
import TextOnlyButton from './TextOnlyButton'
import Modal from './Modal'
import ReactMarkdown from 'react-markdown'

function renderContent(examples) {
  return (
    <ReactMarkdown
      css={`
        table {
          margin: 5px 0 40px;
        }
        table,
        thead,
        tbody,
        tfoot,
        tr,
        th,
        td {
          border-spacing: 0;
          text-align: left;
        }
        table,
        td,
        th {
          border: 1px solid #dedede;
        }
        table {
          table-layout: fixed;
          border-right-width: 0;
          border-bottom-width: 0;
        }
        th {
          background: #f5f5f5;
        }
        td,
        th {
          padding: 5px;
          border-left-width: 0;
          border-top-width: 0;
        }
      `}
      source={examples}
    />
  )
}

export const InvisibleSidebar = styled.div({
  width: 250,
  maxWidth: 250,
  backgroundColor: 'pink',
})
const SidebarContainer = styled.div({
  width: 250,
  maxWidth: 250,
  backgroundColor: colors.baseGreen,
  color: '#fff',
  padding: 26,
  borderRadius: borderRadiuses.default,
})
const SidebarHeading = styled.div({
  fontSize: 17,
  marginBottom: 12,
})
const SidebarText = styled.div({
  marginBottom: 25,
})

function Sidebar({ title = '', subTitle, shortDescription, examples = '' }) {
  return (
    <SidebarContainer>
      <SidebarHeading>{title}</SidebarHeading>
      <SidebarText>
        {shortDescription && shortDescription.content[0].content[0].value}
      </SidebarText>
      <Modal
        hasSheet
        title={
          <div
            css={{
              fontWeight: 600,
              fontSize: 20,
              color: '#4e95f3',
              marginLeft: 5,
            }}
          >
            Exempel på {title.toLowerCase()}
          </div>
        }
        content={renderContent(examples)}
        render={props => {
          return (
            <TextOnlyButton
              css={{ color: '#fff', textDecoration: 'underline' }}
              {...props}
            >
              Se exempel
            </TextOnlyButton>
          )
        }}
      />
    </SidebarContainer>
  )
}

export default Sidebar
