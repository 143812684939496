import React from 'react'
import styled from 'styled-components/macro'

import { useGlobalState } from '../../data/store'

const horizontalPadding = 16

const Container = styled.div({
  marginBottom: 25,
})
const Label = styled.div({
  textTransform: 'uppercase',
  fontSize: '13px',
  fontWeight: '400',
  color: '#aaa',
})
export const Content = styled.div({
  backgroundColor: '#dbf1e7',
  padding: `12px ${horizontalPadding}px`,
  fontSize: 15,
  marginTop: 5,
})

function SelectedObstacle() {
  const [
    {
      selectedObstacleIndex: { values: selectedObstacleIndex },
      obstaclesPage: { values: obstaclesPage },
    },
  ] = useGlobalState()
  const selectedObstacle = obstaclesPage[selectedObstacleIndex]

  return (
    <Container>
      <Label>Valt studiehindrande beteende</Label>
      <Content>{selectedObstacle}</Content>
    </Container>
  )
}

export default SelectedObstacle
