import styled from 'styled-components/macro'

import TransparentButton from './TransparentButton'

const TextOnlyButton = styled(TransparentButton)({
  ':hover': {
    textDecoration: 'underline',
  },
})

export default TextOnlyButton
