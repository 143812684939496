import React from 'react'
import styled from 'styled-components/macro'

import { boxShadows, borderRadiuses, sizes, colors } from '../../lib/constants'

export const Heading = styled.div({
  fontSize: 40,
  paddingBottom: 10,
  marginBottom: 20,
  borderBottom: '1px solid #eee',
})
export const SubmitButtonWrapper = styled.div({
  marginTop: 40,
})
export const Description = styled.div({
  fontWeight: 300,
  color: colors.fontGrey,
  fontSize: 17,
})
export const FormAreaHorizontal = styled.div({
  width: 400,
  maxWidth: 400,
})
export const FormAreaVertical = styled.div({
  marginTop: 40,
  marginBottom: 20,
})
export function FormArea({ children }) {
  return (
    <FormAreaVertical>
      <FormAreaHorizontal>{children}</FormAreaHorizontal>
    </FormAreaVertical>
  )
}

export const Sheet = styled.div({
  backgroundColor: '#fff',
  padding: 30,
  boxShadow: boxShadows.sheet,
  borderRadius: borderRadiuses.default,
  width: '100%',
})
export const MainContent = styled(Sheet)({
  minWidth: sizes.mainContent,
  maxWidth: sizes.mainContent,
  marginLeft: 20,
  marginRight: 20,
  marginBottom: 30,
  paddingtop: 60,
  paddingLeft: 70,
  paddingRight: 70,
})
export const MainContainer = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  '@media (max-width: 1040px)': {
    flexDirection: 'column',
    alignItems: 'center',
  },
})
