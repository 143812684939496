import { useTranslation } from 'react-i18next'

import mockData from './mockData'

function stateTree({ hasSampleData = false } = {}) {
  const { t } = useTranslation()

  let stateTree = {
    stepsCompleted: {
      values: [false, false, false, false],
    },
    selectedObstacleIndex: {
      values: null,
    },
    startPage: {
      values: { student: '', class: '', teacher: '', school: '' },
      labels: {
        student: t('startPage.student'),
        class: t('startPage.class'),
        teacher: t('startPage.teacher'),
        school: t('startPage.school'),
      },
    },
    obstaclesPage: {
      values: Array(6).fill(''),
    },
    situationsPage: {
      values: { allSituations: '', selectedTypeOfObstacle: [] },
    },
    desiredStatePage: {
      values: {
        studentTodos: '',
        pastAttempts: '',
        teacherTodos: '',
        evaluationDate: '',
      },
      labels: {
        studentTodos: t('desiredStatePage.studentTodos'),
        pastAttempts: t('desiredStatePage.pastAttempts'),
        teacherTodos: t('desiredStatePage.teacherTodos'),
        evaluationDate: t('desiredStatePage.evaluationDate'),
      },
    },
  }
  if (hasSampleData) {
    Object.keys(mockData).forEach(key => {
      stateTree = {
        ...stateTree,
        [key]: {
          ...stateTree[key],
          values: mockData[key].values,
        },
      }
    })
  }
  return stateTree
}

export default stateTree
