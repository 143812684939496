import React from 'react'
import { ScaleLoader } from 'react-spinners'
import 'styled-components/macro'

function LoadingIndicator() {
  return (
    <div css={{ display: 'flex', justifyContent: 'center', marginTop: 150 }}>
      <ScaleLoader color="#b8bcbd" />
    </div>
  )
}

export default LoadingIndicator
