import React from 'react'
import styled from 'styled-components/macro'
import TextareaAutosize from 'react-textarea-autosize'

import { borderRadiuses } from '../../lib/constants'

const Container = styled.div({
  marginBottom: 30,
})
export const Label = styled.label({
  fontWeight: 600,
  marginBottom: 5,
  display: 'block',
})
export const focusStyling = {
  border: '1px solid #a4d2ff',
  boxShadow: '0 0 6px rgba(27, 106, 201, 0.5)',
  backgroundColor: '#fff',
}
export const inputStyling = {
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 8,
  paddingBottom: 8,
  width: '100%',
  border: '1px solid #ddd',
  borderRadius: borderRadiuses.default,
  transition: 'box-shadow 0.15s ease-in-out',
  lineHeight: 1.15,
  ':focus': {
    ...focusStyling,
  },
}
const Input = styled.input({
  ...inputStyling,
})
const TextArea = styled(TextareaAutosize).attrs({
  minRows: 4,
})({
  ...inputStyling,
})

export function TextInputSection({
  handleChange,
  label,
  textArea,
  inputStyling,
  ...rest
}) {
  return (
    <Container>
      {label && <Label htmlFor={rest.name}>{label}</Label>}
      {textArea ? (
        <TextArea style={inputStyling} onChange={handleChange} {...rest} />
      ) : (
        <Input
          id={rest.name}
          style={inputStyling}
          onChange={handleChange}
          {...rest}
        />
      )}
    </Container>
  )
}
