export const colors = {
  baseBlack: '#283c46',
  baseBlue: '#1b6ac9',
  baseGreen: '#2da562',
  bodyBgColor: '#f6f2ef',
  fontGrey: '#666',
}

export const boxShadows = {
  sheet: '0 2px 5px 0 rgba(51, 51, 79, 0.07)',
}

export const fontSizes = {
  default: 15,
}

export const borderRadiuses = {
  default: 4,
}

export const sizes = {
  mainContent: 730,
}
