import React from 'react'
import { Link } from 'react-router-dom'

import Button from '../components/reusables/Button'
import {
  Container,
  Paragraph,
  Heading,
  Separator,
  BottomSection,
} from '../components/intro/intro-components'

function Intro({ introParagraphs }) {
  return (
    <Container>
      {introParagraphs.map(({ emoji, title, text }, index) => (
        <div data-testid={`introParagraph` + (index + 1)} key={index}>
          <Heading>
            <span role="img" aria-label="some">
              {emoji}
            </span>{' '}
            {title}
          </Heading>
          <Paragraph>{text}</Paragraph>
          <Separator />
        </div>
      ))}
      <BottomSection>
        <Button as={Link} to="/1">
          Sätt igång →
        </Button>
      </BottomSection>
    </Container>
  )
}

export default Intro
