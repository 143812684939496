import './react-dev-tools'
import React from 'react'
import ReactDOM from 'react-dom'
import 'moment/locale/sv'
import './data/i18n'

import GlobalStyles from './GlobalStyles'
import App from './App'

ReactDOM.render(
  <>
    <GlobalStyles />
    <App />
  </>,
  document.getElementById('root'),
)
