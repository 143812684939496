export default {
  startPage: {
    values: {
      student: 'Klara Ohl',
      class: '6B',
      teacher: 'Mark L.',
      school: 'Hemskolan',
    },
  },
  situationsPage: {
    values: {
      allSituations:
        'Id eiusmod aute enim culpa anim incididunt. Anim minim elit do fugiat velit. Enim est Lorem Lorem veniam et non voluptate veniam laboris. Velit laboris non eu cupidatat ipsum. Voluptate esse mollit veniam Lorem nostrud duis sint voluptate voluptate.',
      selectedTypeOfObstacle: [0],
    },
  },
  obstaclesPage: {
    values: [
      'Sit ipsum nulla id in elit veniam dolore cupidatat.',
      'Eleven pratar ofta rakt ut, utan att räcka upp handen.',
      'Laboris laborum eiusmod tempor aute occaecat eu velit.',
      '',
      '',
      '',
    ],
  },
  selectedObstacleIndex: { values: 1 },
  stepsCompleted: { values: [true, true, true, true] },
  desiredStatePage: {
    values: {
      studentTodos:
        'Mollit eiusmod quis nisi eiusmod velit occaecat.\nUllamco velit non minim Lorem qui commodo occaecat. \n\nElit quis consequat duis irure aliqua mollit enim. Qui culpa laborum consectetur velit est incididunt anim dolore. Culpa ut cillum do ullamco consequat consectetur deserunt ad nisi ea veniam eu eiusmod. Ea commodo officia reprehenderit non dolor fugiat deserunt in.',
      pastAttempts:
        'Id exercitation proident qui culpa deserunt enim voluptate non et enim esse.',
      teacherTodos:
        'Mollit dolore ad commodo incididunt duis occaecat consectetur consectetur ut reprehenderit id nulla.',
      evaluationDate: '12 Maj 2019',
    },
  },
}
