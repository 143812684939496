import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from './Button'
import { SubmitButtonWrapper } from './page-elements'

function SubmitButton({ isSubmittable, handleSubmit }) {
  const { t } = useTranslation()
  return (
    <SubmitButtonWrapper>
      <Button blue shrunk disabled={!isSubmittable} onClick={handleSubmit}>
        {t('submitButton')}
      </Button>
    </SubmitButtonWrapper>
  )
}

export default SubmitButton
