import React from 'react'
import styled from 'styled-components/macro'

const SectionContainer = styled.div({
  marginBottom: 55,
  fontSize: 15,
  pageBreakInside: 'avoid',
})
const SectionHeading = styled.div({
  paddingBottom: 10,
  borderBottom: '1px solid #eee',
  fontSize: 17,
  fontWeight: 600,
})
const SectionContent = styled.div({
  marginTop: 25,
  marginLeft: 20,
})

function Section({ heading, children, contentStyling }) {
  return (
    <SectionContainer>
      <SectionHeading>{heading}</SectionHeading>
      <SectionContent style={contentStyling}>{children}</SectionContent>
    </SectionContainer>
  )
}

export default Section
