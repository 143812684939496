import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import TextOnlyButton from './reusables/TextOnlyButton'
import TransparentButton from './reusables/TransparentButton'
import { isProductionEnv } from '../lib/utilFunctions'
import image from '../assets/images/logga.png'
import { ReactComponent as Circle } from '../assets/images/circle.svg'

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 35px 10px 10px',
  alignItems: 'center',
})
const RightSection = styled.div({
  marginLeft: 'auto',
  display: 'flex',
  opacity: 0.7,
})

function Logo() {
  return (
    <div css={{ display: 'flex', alignItems: 'center' }}>
      <img css={{ height: 60 }} src={image} alt="" />
      <div css={{ opacity: 0.7 }}>När ett hinder uppstår</div>
    </div>
  )
}

export function TopBarLoggedOut() {
  return (
    <Wrapper>
      <Logo />
    </Wrapper>
  )
}
export function TopBarLoggedIn({ isIntroPage }) {
  const handleCircleClick = event => {
    if (window.confirm('Är du säker på att du vill börja om?')) {
      return true
    } else {
      event.preventDefault()
    }
  }

  return (
    <Wrapper>
      <Logo />
      <RightSection>
        {!isIntroPage && (
          <>
            <TransparentButton
              title="Börja om"
              css={{ display: 'inline-grid' }}
              onClick={e => handleCircleClick(e)}
              to="/"
              as={Link}
            >
              <Circle css={{ width: 20 }} />
            </TransparentButton>
            <div css={{ color: '#ddd', margin: '0 10px 0 8px' }}>|</div>
          </>
        )}
        <TextOnlyButton
          onClick={() => {
            window.localStorage.clear()
            if (isProductionEnv()) {
              window.Intercom('shutdown')
            }
            window.location.reload()
          }}
        >
          Logga ut
        </TextOnlyButton>
      </RightSection>
    </Wrapper>
  )
}
