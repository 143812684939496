import React, { createContext, useContext, useReducer } from 'react'

export const StateContext = createContext()

export const StateProvider = ({ initialState, children }) => (
  <StateContext.Provider
    value={useReducer((state, action) => {
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          values: action.value,
        },
      }
    }, initialState)}
  >
    {children}
  </StateContext.Provider>
)

export const useGlobalState = () => useContext(StateContext)
